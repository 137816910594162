html{
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  body {
    margin: 0;
    height: 100%;
    background: #000;
  }
  
  
  #root{
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  
  main{
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  main>div{
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
  code {
    
  }
  
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/variables';

.mapa-container {
  background-color: $brown;
  width: 99vw;
  padding: 64px 0;
  margin-bottom: 0;

  .mapa-content {
    max-width: 750px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    .boton-ver-mapa {
      font-size: 18px;
      border-color: $white;
      height: 42px;
      width: 200px;
    }

    .boton-ver-mapa:hover {
      border-color: $white;
    }

    h4 {
      margin: 24px 0;
    }
  }
}

//Colors
$blue: #477CEE;
$lightBlue: #5E93FC;
$lightBlueFooter: #00B4EF;
$grey: #858BA5;
$green: #62D862;
$lightGrey: #465057;
$white: #FFF;
$black: #000;
$brown: #3F3019;
$lighBrown: #7D6648;
$lighBrownHover: #a88354;
$lighBrownPress: #564b3d;

//Breakpoint;
$mobile-width: 600px;
$mobile-height: 900px;
// $mobile-height: 0;

$desktop-width: 1440px;
$desktop-height: 900px;

$lineColor: #2D2D2D;